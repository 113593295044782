import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Section, Container } from "./utils"
import styled from "styled-components"

const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  p,
  a,
  h4 {
    color: #ccc;
  }
  a.handy-link {
    display: block;
    z-index: 11;
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }
`
const FooterContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`
const BlockLeft = styled.div`
  line-height: 1.7rem;
  text-align: right;
  padding-right: 1rem;
  border-right: 1px solid white;
  p {
    margin-bottom: 0;
    font-weight: 500;
  }
`
const BlockRight = styled.div`
  line-height: 1.3rem;
  padding-left: 1rem;

  p {
    margin-bottom: 0;
    font-weight: 500;
  }
`
const Icon = styled.div`
  height: 15px;
  width: 15px;
  float: right;
  margin-left: 10px;
  svg {
    fill: white;
    height: 15px;
    width: 15px;
  }
`
export default () => {
  const data = useStaticQuery(graphql`
    {
      page: datoCmsContactPage {
        phoneNumber
        emailAddress
        address {
          line
        }
        socialMediaProfiles {
          iconSvgCode
          link
        }
      }
      allDatoCmsExtraPage {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `)
  const { phoneNumber, emailAddress, address, socialMediaProfiles } = data.page
  return (
    <Footer>
      <Section color="navy" padding="medium">
        <FooterContainer>
          <BlockLeft>
            <p>
              {phoneNumber}
              <br /> {emailAddress}{" "}
            </p>
            {socialMediaProfiles.map(({ iconSvgCode, link }) => (
              <Icon>
                <>
                  {iconSvgCode && (
                    <a
                      href={link}
                      dangerouslySetInnerHTML={{ __html: iconSvgCode }}
                    />
                  )}
                </>
              </Icon>
            ))}
          </BlockLeft>
          <BlockRight>
            <p>
              {address.map(({ line }) => (
                <>
                  {line} <br />
                </>
              ))}
            </p>
          </BlockRight>
        </FooterContainer>
        <Container className="text-center">
          {data.allDatoCmsExtraPage.edges && (
            <>
              <h4 className="mb-1">Handy links</h4>
              {data.allDatoCmsExtraPage.edges.map(
                ({ node: { title, slug } }) => (
                  <a className="handy-link" href={`/pages/${slug}`}>
                    {title}
                  </a>
                )
              )}
            </>
          )}
        </Container>
        <Container className="text-center">
          <p>©D&R Property Services UK Ltd {new Date().getFullYear()}.</p>
          <p>
            Another cracking site by{" "}
            <a href="https://kozocreative.com">Kozo Creative</a>
          </p>
        </Container>
      </Section>
    </Footer>
  )
}
