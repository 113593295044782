import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import Navbar from "./navbar/index"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "../styles/global"
import Footer from "./footer"

const theme = {
  navy: `#1d2647`,
  blue: `#1f3b71`,
  paleBlue: `rgba(31, 59, 113, 0.1)`,
  orange: `#4D8C32`,
  darkOrange: `#207f19`,
  light: `#ffffff`,
  dark: `#333333`,
  background: `#f7f8f9`,
}

const Layout = ({ children, isHome }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/hlu6jpx.css" />
      </Helmet>
      <GlobalStyle />
      <Navbar isHome={isHome} />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
