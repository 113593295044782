import React from "react"
import styled from "styled-components"

const Form = styled.form`
  .group {
    display: flex;
    flex-wrap: wrap;
    margin: -0.6rem;
    margin-bottom: 0.6rem;
    input {
      margin: 0.6rem;
      flex: 1 1 250px;
    }
  }
  input:not([type="submit"]),
  textarea {
    background: rgba(255, 255, 255, 0.25);
    color: ${props => (props.inverse ? "#333" : "white")};
    font-weight: 500;
    font-size: 0.9rem;
    border: 2px solid #999;
    border-radius: 3px;
    padding: 0.4rem;
    width: 100%;
    appearance: none;
    margin-bottom: 1.2rem;
    &::placeholder {
      color: #999;
    }
  }
`

export default ({ inverse, name = "Home" }) => {
  return (
    <Form
      name={name}
      data-netlify="true"
      inverse={inverse}
      action="/contact?success=true"
      method="POST"
    >
      <input type="hidden" name="form-name" value={name} />
      <div className="group">
        <input type="text" placeholder="Name" name="Name" required />
        <input type="text" placeholder="Email" name="Email" required />
        <input type="text" placeholder="Phone" name="Phone" />
      </div>
      <textarea name="Message" rows="8" placeholder="Message"></textarea>
      <div style={{ textAlign: "center" }}>
        <input type="submit" className="button submit" value="Send" />
      </div>
    </Form>
  )
}
