import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 14px;
    line-height: 1.3;
    box-sizing: border-box;
  }
  @media screen and (min-width: 320px) {
    html {
      font-size: calc(14px + 6 * ((100vw - 320px) / 680));
    }
  }
  @media screen and (min-width: 1000px) {
    html {
      font-size: 18px;
    }
  }

  * {
    font-family: omnes-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body, h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  body {
    background: ${props => props.theme.background};
  }

  h1, h2, h3, h4 {
    font-family: rhode, sans-serif;
    margin-bottom: 1.5rem;
    &:not(h1) {
      color: ${props => props.theme.blue};
      &.uppercase {
        text-transform: uppercase;
      }
      &.underlined::after {
          content: '';
          width: 100%;
          border-bottom: solid 2px currentColor;
          display: block;
          margin-top: 10px;
      }
    }
  }

  h1 {
    font-size: 2.5rem;
    color: ${props => props.theme.blue};
  }
  @media(min-width: 960px) {
    h1 {
      font-size: 3rem;
    }
  }

  h3 {
    margin-bottom: .8rem;
    margin-top: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    color: ${props => props.theme.orange};
    text-decoration: none;
    font-weight: 500;
  }

  ol, ul {
    margin-left: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  main {
    min-height: 100vh;
    background: ${props => props.theme.background};
    position: relative;
    z-index: 1;
  }

  .text-center {
    text-align: center;
  }
  .text-orange {
    color: ${props => props.theme.orange};
  }
  .text-white {
    color: white !important;
  }

  button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
  }
  
  .button {
    font-family: rhode, sans-serif;
    background: ${props => props.theme.orange};
    padding: 15px 30px;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 5rem;
    font-size: 0.8rem;
    transition: all ease .25s;
    cursor: pointer;
    a {
      color: white;
    }
    &:hover {
      background: ${props => props.theme.darkOrange};
    }
  }
  &.button-outline {
    background: transparent;
    border: 2px solid ${props => props.theme.orange};
    color: ${props => props.theme.orange};
    &:hover {
      background: ${props => props.theme.orange};
      color: white;
    }
  }
  .contact-icon {
    margin-right: 10px;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .mb-2 {
    margin-bottom: 2rem;
  }
  .mb-3 {
    margin-bottom: 3rem;
  }
  .mt-2 {
    margin-top: 2rem;
  }
`
export default GlobalStyle
