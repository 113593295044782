import React from "react"
import styled from "styled-components"

const Hamburger = styled.div`
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 300;
  transition: transform ease 0.3s;
  cursor: pointer;
  /* position: ${props => props.open && `fixed`}; */
  span {
    width: 90%;
    height: 2px;
    background-color: ${props =>
      props.isHome ? props.theme.light : props.theme.blue};
    border-radius: 10px;
    transition: all ease 0.3s;
    &:nth-child(1) {
      width: 90%;
      ${props =>
        props.open &&
        `width: 100%;
          transform: translateY(9px) rotate(45deg);`}
    }
    &:nth-child(2) {
      width: 100%;
      ${props =>
        props.open &&
        `
          opacity: 0;`}
    }
    &:nth-child(3) {
      width: 90%;
      ${props =>
        props.open &&
        `
          width: 100%;
          transform: translateY(-9px) rotate(-45deg);
        `}
    }
  }
  @media screen and (min-width: 992px) {
    display: none;
  }
`

const Toggle = props => {
  return (
    <Hamburger
      open={props.open}
      onClick={props.handleClick}
      isHome={props.isHome}
    >
      <span />
      <span />
      <span />
    </Hamburger>
  )
}

export default Toggle
