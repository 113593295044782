import React from "react"
import styled from "styled-components"
import { Container } from "./index"
import Form from "./form"

const ContactSection = styled.section`
  padding: 5rem 0;
  background: url("https://www.transparenttextures.com/patterns/buried.png")
    ${props => props.theme.blue};
  h2 {
    color: white;
  }
  .group {
    display: flex;
    flex-wrap: wrap;
    margin: -0.6rem;
    margin-bottom: 0.6rem;
    input {
      margin: 0.6rem;
      flex: 1 1 250px;
    }
  }
  input:not([type="submit"]),
  textarea {
    background: rgba(255, 255, 255, 0.25);
    color: white;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 0.4rem;
    width: 100%;
    appearance: none;
    margin-bottom: 1.2rem;
    &::placeholder {
      color: #ccc;
    }
  }
`

export default ({ children }) => {
  return (
    <ContactSection>
      <Container>
        <h2 className="text-center no-line uppercase">Contact Us</h2>
        <Form />
      </Container>
    </ContactSection>
  )
}
