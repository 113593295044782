import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLogo = styled.svg`
  margin-bottom: 0;
  height: 80px;
  fill: ${props => props.theme.light};
  z-index: 300;
  .letters {
    fill: ${props => (props.isHome ? "#fff" : props.theme.blue)};
  }
  #ampersand {
    fill: ${props => props.theme.orange};
  }
`

const Logo = props => {
  return (
    <Link to="/" style={{ zIndex: 300 }}>
      <StyledLogo
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 94.08 48.96"
        isHome={props.isHome}
      >
        <defs></defs>
        <g>
          <path
            className="letters"
            d="M35.65 28a6.85 6.85 0 012.2-5 6.52 6.52 0 01-1.36-4.11 6.62 6.62 0 011.72-4.58 10 10 0 00-1-1.6C35.28 10 31.74 8.56 26 8.56H8.64V40h17.84c5.69 0 9-1.64 10.87-4.54a11.37 11.37 0 001-1.87 7.14 7.14 0 01-2.7-5.59zm-6.42 2.85c-1.08 1.27-2.39 1.79-4.86 1.79h-6.1V15.92h6.1c2.39 0 3.54.36 4.78 1.72.79.87 1 3.78 1 6.05.03 2.55-.09 6.13-.92 7.13zM78 27.75a6.14 6.14 0 003.9-3 11.5 11.5 0 001.4-5.93c0-2.95-.6-5.06-1.84-6.65-1.59-2.11-4.34-3.31-8.56-3.31h-20v8.34h2v2.58h2.47v7.55h-2.55v.22a7.7 7.7 0 01-2 5.25v7.53h9.56V29.7h6.53l5.58 10.63h10.9zm-4.54-6.1c-.36.76-1.39 1-2.59 1h-8.38v-6.37h8.16c1.4 0 2.35.32 2.83 1.31a5.19 5.19 0 01.32 2 5.67 5.67 0 01-.36 2.06z"
          />
          <path
            id="ampersand"
            d="M40.92 23.17v-.06a4.56 4.56 0 01-2.6-4.27c0-3.86 3.38-5.36 6.74-5.36a13.59 13.59 0 012.94.34l-1.11 3.8a5.64 5.64 0 00-1.08-.11c-1.42 0-2.58.66-2.58 2a1.93 1.93 0 002 2.08h3V19H53v2.58h2.47v3.92H53v2.05C53 31.1 50.14 34 45.2 34c-4.75 0-7.72-2.69-7.72-6a5.15 5.15 0 013.44-4.83zm4.33 6.52c1.83 0 3-1 3-2.42V25.5H45c-1.28 0-2.5.55-2.5 2s1.06 2.19 2.75 2.19z"
          />
        </g>
      </StyledLogo>
    </Link>
  )
}
export default Logo
